import { useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { Layout, message } from 'antd';
import { useAuthenticationState } from 'hooks/useAuthenticationState';
import { useIncomingChats } from 'hooks/useIncomingChats';
import { useLogout } from 'hooks/useLogout';
import { useResponsiveDashboardLayout } from 'hooks/useResponsiveDashboardLayout';

import { Lawyer } from 'api/generated/models/auth';
import { useGetAuthenticationsMe } from 'api/generated/services/auth/authentication/authentication';
import { PageSidebar } from 'components';
import PageFooter from 'components/page-footer';
import { PageHeader } from 'components/page-header';
import { useCurrentUserStore } from 'store/current-user';

import { LayoutProps } from '../types';
import { ALLOWED_ROLE } from './constants';

export function DashboardLayout({ children }: LayoutProps) {
  const { pathname } = useLocation();
  const { isFullyAuthenticated } = useAuthenticationState();
  const { setCurrentUser } = useCurrentUserStore(
    (currentUserState) => currentUserState.actions
  );
  const { logout } = useLogout();

  useEffect(() => {
    // Check if the browser supports notifications
    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          // TODO: Add notification permission granted log
        } else {
          // TODO: Add notification permission denied log
        }
      });
    }
  }, []);

  useGetAuthenticationsMe({
    query: {
      onSuccess(data) {
        const currentUser = data.data;
        const currentUserProfile = currentUser.profile as Lawyer;

        if (!ALLOWED_ROLE.includes(currentUser.role)) {
          logout();
          message.error(
            'Akses tidak tersedia untuk akun selain advokat dan admin'
          );
          return;
        }

        const disableAutoOffline =
          currentUser.email === 'fhutapealaw@gmail.com';

        setCurrentUser({
          id: currentUser.id,
          consultId: currentUser.consult_id,
          firstName: currentUser.first_name,
          lastName: currentUser.last_name,
          picture: currentUser.picture,
          isOnline: currentUser.is_online,
          lawyerProfileUrl: currentUserProfile?.lawyer_profile_url,
          role: currentUser.role,
          disableAutoOffline,
        });
      },
    },
  });

  useIncomingChats();

  useResponsiveDashboardLayout();

  const showSidebar = useMemo(() => {
    const definedStatusVerifications = [
      '/verification/waiting',
      '/verification/reject',
      '/verification/banned',
    ];
    const notFoundPageVerificationPath =
      pathname?.includes('/verification') &&
      pathname?.split('/')?.length > 2 &&
      !definedStatusVerifications?.includes(pathname);

    return (
      !notFoundPageVerificationPath &&
      !definedStatusVerifications?.includes(pathname)
    );
  }, [pathname]);

  if (!isFullyAuthenticated) {
    localStorage.clear();
    window.location.replace('/auth/login');
  }

  return (
    <div className="hk-cms">
      <PageHeader />
      <Layout className="hk-cms-layout">
        {showSidebar && <PageSidebar />}
        {children ?? <Outlet />}
      </Layout>
      <PageFooter />
    </div>
  );
}
