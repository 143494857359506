import './style.scss';

import { useMemo, useState } from 'react';

import { Button, Card, Col, Divider, Row, Space, Typography } from 'antd';
import dayjs from 'dayjs';

import {
  ConsultationBasicCase,
  LegalDraft,
  Order,
} from 'api/generated/models/case';
import { ReactComponent as PaperIc } from 'assets/svg/paper-ic.svg';
import { StatusTag } from 'components/status-tag';
import { useOfferLegalDrafts } from 'pages/legal-drafting/hooks/useOfferLegalDrafts';
import { LEGAL_DRAFT_PAYMENT_STATUS } from 'pages/legal-drafting/types';
import { getStatusProperty } from 'pages/legal-drafting/utils';
import { currencyFormatter } from 'utils';

import { CreateOfferModal } from '../modal/CreateOfferModal';
import { LegalDraftModal } from '../modal/LegalDraftModal';
import { UploadLegalDraftModal } from '../modal/UploadLegalDraftModal';

interface OfferCardProps {
  orders: Order | undefined;
}

type ModalVariant = 'upload' | 'legalDraft' | 'detail';

export const OfferCard = ({ orders }: OfferCardProps) => {
  const pendingStatus = ['not_started', 'executing'];
  const successStatus = ['success'];
  const failedStatus = ['failed', 'late'];

  const [isModalOpen, setIsModalOpen] = useState({
    upload: false,
    legalDraft: false,
  });
  const { order_items: orderItems = [], product, payment } = orders || {};
  const { topic } = (product as ConsultationBasicCase | LegalDraft)?.case || {};
  const {
    onCloseCreateLegalDraftsModal,
    isCreateOfferModalOpen,
    onOpenCreateLegalDraftsModal,
  } = useOfferLegalDrafts();

  const amount = orderItems[0]?.amount ? Number(orderItems[0]?.amount) : 0;
  const dueDate = orderItems[0]?.detail?.['DueDate'];
  const description = orderItems[0]?.detail?.['Description'];

  const paymentStatus = payment?.status
    ? (payment?.status as LEGAL_DRAFT_PAYMENT_STATUS)
    : LEGAL_DRAFT_PAYMENT_STATUS.EXECUTING;

  const productStatus = product?.status;

  const documentTitle = orderItems[0]?.detail?.['Name'];
  const legalDraftId = orderItems[0]?.detail?.['LegalDraftID'];

  const formattedDueDate = dueDate
    ? dayjs.unix(dueDate).format('DD/MM/YYYY')
    : '-';

  const onOpenModal = (modalVariant: ModalVariant) => {
    if (modalVariant === 'detail') {
      onOpenCreateLegalDraftsModal();
    }

    setIsModalOpen((prevState) => ({ ...prevState, [modalVariant]: true }));
  };
  const onCloseModal = () => {
    setIsModalOpen({
      legalDraft: false,
      upload: false,
    });
  };

  const statusProperty = useMemo(
    () => getStatusProperty(paymentStatus),
    [paymentStatus]
  );

  return (
    <>
      {isCreateOfferModalOpen && (
        <CreateOfferModal
          action="view"
          onCancel={onCloseCreateLegalDraftsModal}
          open={isCreateOfferModalOpen}
          orderId={orders?.id}
        />
      )}
      <LegalDraftModal onCancel={onCloseModal} open={isModalOpen.legalDraft} />
      {isModalOpen.upload && (
        <UploadLegalDraftModal
          legalDraftId={legalDraftId}
          onCancel={onCloseModal}
          open={isModalOpen.upload}
        />
      )}
      <Card
        className="offer-card__container"
        extra={
          <Typography.Paragraph className="hk-margin-bottom-none">
            <StatusTag {...statusProperty} />
          </Typography.Paragraph>
        }
        title={
          <div className="topic-wrapper">
            <PaperIc />
            <Typography.Paragraph
              className="font-16 weight-500 hk-color-black-title hk-margin-bottom-none"
              ellipsis>
              {topic}
            </Typography.Paragraph>
          </div>
        }>
        <Typography.Paragraph
          className="font-14 weight-500 hk-color-black-title hk-margin-bottom-sm"
          ellipsis>
          {documentTitle}
        </Typography.Paragraph>
        <Typography.Paragraph
          className="font-12 weight-400 hk-color-dark-grey hk-margin-bottom-sm"
          ellipsis>
          {description}
        </Typography.Paragraph>

        <Divider />

        <Row align="middle" justify="space-between">
          <Col md={14} xs={12}>
            <Space align="start" direction="vertical">
              <Typography.Paragraph className="font-16 weight-600 hk-color-green hk-margin-bottom-none">
                {currencyFormatter(amount)}
              </Typography.Paragraph>
              {!successStatus.includes(paymentStatus) && (
                <Typography.Paragraph className="font-10 weight-400 hk-color-dark-grey hk-margin-bottom-none">
                  Berlaku sampai {formattedDueDate}
                </Typography.Paragraph>
              )}
            </Space>
          </Col>
          <Col md={10} xs={12}>
            <Button
              className="detail-button w-full"
              disabled={failedStatus.includes(paymentStatus)}
              onClick={() => {
                if (pendingStatus.includes(paymentStatus)) {
                  onOpenModal('detail');
                }

                if (
                  successStatus.includes(paymentStatus) &&
                  productStatus === 'in_progress'
                ) {
                  onOpenModal('upload');
                }
              }}
              size="middle"
              type="primary">
              <Typography.Text
                className="font-12 weight-500 hk-color-white"
                ellipsis>
                {successStatus.includes(paymentStatus) &&
                productStatus === 'in_progress'
                  ? 'Unggah Dokumen'
                  : 'Lihat Detail'}
              </Typography.Text>
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};
