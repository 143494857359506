import { MessageSeparator } from '@chatscope/chat-ui-kit-react';
import { Typography } from 'antd';
import { Message as TinodeMessage } from 'libs/tinode/types/sdk/Message';

import { MESSAGE_VIOLATION_ERROR } from 'config/constants';

import { Message } from '../message';
interface RenderMessageListProps {
  eachMessage: TinodeMessage;
  consultId: string;
  onOpenViolatedModal: () => void;
}

export const RenderMessageList = ({
  eachMessage,
  consultId,
  onOpenViolatedModal,
}: RenderMessageListProps) => {
  const messageType = eachMessage?.content?.type;
  const messageContent = eachMessage?.content?.txt?.message;

  if (messageType === 'system') {
    return (
      <MessageSeparator
        className="hk-margin-top-lg hk-margin-bottom-md"
        key={eachMessage.seq}>
        <Typography.Text className="w-full hk-align-text-center font-12 weight-400 hk-color-black-title">
          {eachMessage.content.message}
        </Typography.Text>
      </MessageSeparator>
    );
  }

  return (
    <Message
      currentUserTopicName={consultId}
      isViolated={messageContent === MESSAGE_VIOLATION_ERROR}
      key={eachMessage.seq}
      message={eachMessage}
      onOpenViolatedModal={onOpenViolatedModal}
    />
  );
};
