/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * HukumkuRestAPI-AuthService
 * OpenAPI spec version: 1.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';

import type {
  BodyType,
  ErrorType,
} from '../../../../utils/auth-service-request';
import { authServiceRequest } from '../../../../utils/auth-service-request';
import type {
  ChangePassword,
  Error,
  GetUsers200,
  GetUsersParams,
  GetUsersProfilePictureParams,
  ListUsersProfilePicture,
  PatchUsersConsultIdPresenceBody,
} from '../../../models/auth';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Find all User
 */
export const getUsers = (
  params?: GetUsersParams,
  options?: SecondParameter<typeof authServiceRequest>,
  signal?: AbortSignal
) => {
  return authServiceRequest<GetUsers200>(
    { url: `/users`, method: 'get', params, signal },
    options
  );
};

export const getGetUsersQueryKey = (params?: GetUsersParams) =>
  [`/users`, ...(params ? [params] : [])] as const;

export const getGetUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof getUsers>>,
  TError = ErrorType<Error>,
>(
  params?: GetUsersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUsers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof authServiceRequest>;
  }
): UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUsersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsers>>> = ({
    signal,
  }) => getUsers(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUsers>>
>;
export type GetUsersQueryError = ErrorType<Error>;

/**
 * @summary Find all User
 */
export const useGetUsers = <
  TData = Awaited<ReturnType<typeof getUsers>>,
  TError = ErrorType<Error>,
>(
  params?: GetUsersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUsers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof authServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUsersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Find Users profile picture
 */
export const getUsersProfilePicture = (
  params?: GetUsersProfilePictureParams,
  options?: SecondParameter<typeof authServiceRequest>,
  signal?: AbortSignal
) => {
  return authServiceRequest<ListUsersProfilePicture>(
    { url: `/users-profile-picture`, method: 'get', params, signal },
    options
  );
};

export const getGetUsersProfilePictureQueryKey = (
  params?: GetUsersProfilePictureParams
) => [`/users-profile-picture`, ...(params ? [params] : [])] as const;

export const getGetUsersProfilePictureQueryOptions = <
  TData = Awaited<ReturnType<typeof getUsersProfilePicture>>,
  TError = ErrorType<Error>,
>(
  params?: GetUsersProfilePictureParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUsersProfilePicture>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof authServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getUsersProfilePicture>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUsersProfilePictureQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUsersProfilePicture>>
  > = ({ signal }) => getUsersProfilePicture(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetUsersProfilePictureQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUsersProfilePicture>>
>;
export type GetUsersProfilePictureQueryError = ErrorType<Error>;

/**
 * @summary Find Users profile picture
 */
export const useGetUsersProfilePicture = <
  TData = Awaited<ReturnType<typeof getUsersProfilePicture>>,
  TError = ErrorType<Error>,
>(
  params?: GetUsersProfilePictureParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUsersProfilePicture>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof authServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUsersProfilePictureQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update user presence
 */
export const patchUsersConsultIdPresence = (
  consultId: string,
  patchUsersConsultIdPresenceBody: BodyType<PatchUsersConsultIdPresenceBody>,
  options?: SecondParameter<typeof authServiceRequest>
) => {
  return authServiceRequest<void>(
    {
      url: `/users/${consultId}/presence`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchUsersConsultIdPresenceBody,
    },
    options
  );
};

export const getPatchUsersConsultIdPresenceMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUsersConsultIdPresence>>,
    TError,
    { consultId: string; data: BodyType<PatchUsersConsultIdPresenceBody> },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchUsersConsultIdPresence>>,
  TError,
  { consultId: string; data: BodyType<PatchUsersConsultIdPresenceBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchUsersConsultIdPresence>>,
    { consultId: string; data: BodyType<PatchUsersConsultIdPresenceBody> }
  > = (props) => {
    const { consultId, data } = props ?? {};

    return patchUsersConsultIdPresence(consultId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchUsersConsultIdPresenceMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchUsersConsultIdPresence>>
>;
export type PatchUsersConsultIdPresenceMutationBody =
  BodyType<PatchUsersConsultIdPresenceBody>;
export type PatchUsersConsultIdPresenceMutationError = ErrorType<Error>;

/**
 * @summary Update user presence
 */
export const usePatchUsersConsultIdPresence = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUsersConsultIdPresence>>,
    TError,
    { consultId: string; data: BodyType<PatchUsersConsultIdPresenceBody> },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}) => {
  const mutationOptions =
    getPatchUsersConsultIdPresenceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Delete account could be lawyer or client
 */
export const deleteUsersId = (
  id: string,
  options?: SecondParameter<typeof authServiceRequest>
) => {
  return authServiceRequest<void>(
    { url: `/users/${id}`, method: 'delete' },
    options
  );
};

export const getDeleteUsersIdMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUsersId>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUsersId>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUsersId>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteUsersId(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUsersIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUsersId>>
>;

export type DeleteUsersIdMutationError = ErrorType<Error>;

/**
 * @summary Delete account could be lawyer or client
 */
export const useDeleteUsersId = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUsersId>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}) => {
  const mutationOptions = getDeleteUsersIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Request delete account
 */
export const postUsersRequestDeleteAccount = (
  options?: SecondParameter<typeof authServiceRequest>
) => {
  return authServiceRequest<void>(
    { url: `/users/request-delete-account`, method: 'post' },
    options
  );
};

export const getPostUsersRequestDeleteAccountMutationOptions = <
  TError = ErrorType<Error>,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUsersRequestDeleteAccount>>,
    TError,
    TVariables,
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUsersRequestDeleteAccount>>,
  TError,
  TVariables,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUsersRequestDeleteAccount>>,
    TVariables
  > = () => {
    return postUsersRequestDeleteAccount(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUsersRequestDeleteAccountMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUsersRequestDeleteAccount>>
>;

export type PostUsersRequestDeleteAccountMutationError = ErrorType<Error>;

/**
 * @summary Request delete account
 */
export const usePostUsersRequestDeleteAccount = <
  TError = ErrorType<Error>,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUsersRequestDeleteAccount>>,
    TError,
    TVariables,
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}) => {
  const mutationOptions =
    getPostUsersRequestDeleteAccountMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary User Change Password
 */
export const patchUsersPassword = (
  changePassword: BodyType<ChangePassword>,
  options?: SecondParameter<typeof authServiceRequest>
) => {
  return authServiceRequest<void>(
    {
      url: `/users/password`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: changePassword,
    },
    options
  );
};

export const getPatchUsersPasswordMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUsersPassword>>,
    TError,
    { data: BodyType<ChangePassword> },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchUsersPassword>>,
  TError,
  { data: BodyType<ChangePassword> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchUsersPassword>>,
    { data: BodyType<ChangePassword> }
  > = (props) => {
    const { data } = props ?? {};

    return patchUsersPassword(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchUsersPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchUsersPassword>>
>;
export type PatchUsersPasswordMutationBody = BodyType<ChangePassword>;
export type PatchUsersPasswordMutationError = ErrorType<Error>;

/**
 * @summary User Change Password
 */
export const usePatchUsersPassword = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUsersPassword>>,
    TError,
    { data: BodyType<ChangePassword> },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}) => {
  const mutationOptions = getPatchUsersPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
