import { Link } from 'react-router-dom';

import { StepsProps, Typography } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';

import { ReactComponent as DocumentIcon } from 'assets/svg/directbox-notif.svg';
import { ReactComponent as FaqIcon } from 'assets/svg/faq-ic.svg';
import { ReactComponent as HomeIcon } from 'assets/svg/home-nav-ic.svg';
import { ReactComponent as LegalAssistantIcon } from 'assets/svg/legal-assistant-nav-ic.svg';
import { ReactComponent as MessageIcon } from 'assets/svg/message-nav-ic.svg';
import { ReactComponent as MyCasesIcon } from 'assets/svg/my-cases-nav-ic.svg';
import { ReactComponent as CreditIcon } from 'assets/svg/sidebar-setting-credit-ic.svg';
import { ReactComponent as PackageIcon } from 'assets/svg/sidebar-setting-package-ic.svg';
import { ReactComponent as ProfileIcon } from 'assets/svg/sidebar-setting-profile-ic.svg';
import { ReactComponent as WorkBagIcon } from 'assets/svg/work-bag-ic.svg';

export const MENU_SIDEBAR_ITEMS: ItemType[] = [
  {
    label: 'FITUR HUKUMKU',
    key: 'feature',
    type: 'group',
    children: [
      {
        key: 'legal-assistant',
        label: (
          <Link className="hk-padding-xs" to="/legal-assistant">
            <Typography.Paragraph className="hk-margin-none weight-500">
              Mesin Pencari Dokumen
            </Typography.Paragraph>
            <Typography.Paragraph className="beta">Beta</Typography.Paragraph>
          </Link>
        ),
        icon: <LegalAssistantIcon />,
        className: 'beta-feature hk-padding-top-sm hk-padding-bottom-sm',
      },
      {
        key: 'find-case',
        label: (
          <Link className="hk-padding-xs" to="/find-case">
            <Typography.Paragraph className="hk-margin-none weight-500">
              Cari Kasus Disini!
            </Typography.Paragraph>
            <Typography.Paragraph className="new">Baru</Typography.Paragraph>
          </Link>
        ),
        icon: <DocumentIcon />,
        className: 'new-feature hk-padding-top-sm hk-padding-bottom-sm',
      },
      {
        key: 'legal-connect',
        label: (
          <Link className="hk-padding-xs" to="/legal-connect">
            <Typography.Paragraph className="hk-margin-none">
              Legal Connect
            </Typography.Paragraph>
            <Typography.Paragraph className="new">Baru</Typography.Paragraph>
          </Link>
        ),
        icon: <DocumentIcon />,
        className: 'new-feature hk-padding-top-sm hk-padding-bottom-sm',
      },
    ],
  },
  {
    label: 'MENU',
    key: 'menu',
    type: 'group',
    children: [
      {
        key: 'home',
        label: <Link to="/home">Beranda</Link>,
        icon: <HomeIcon />,
      },
      {
        key: 'consultation',
        label: 'Konsultasi',
        icon: <MessageIcon />,
        children: [
          {
            key: 'message',
            label: <Link to="/message">Pesan</Link>,
          },
          {
            key: 'legal-drafting',
            label: <Link to="/legal-drafting">Legal Drafting</Link>,
          },
        ],
      },
      {
        key: 'my-cases',
        label: <Link to="/my-cases">Kasus Saya</Link>,
        icon: <MyCasesIcon />,
      },
      {
        key: 'share',
        label: (
          <Link target="_blank" to="https://forms.gle/wg7kuJwZLikHcjxDA">
            Ajak Rekan
          </Link>
        ),
        icon: <WorkBagIcon />,
      },
      {
        key: 'faq',
        label: <Link to="/faq">FAQ</Link>,
        icon: <FaqIcon />,
      },
    ],
  },
];

export const VERIFICATION_STEPS_ITEMS: StepsProps['items'] = [
  {
    title: 'Lengkapi profil Anda',
  },
  {
    title: 'Area Praktik Hukum',
  },
  {
    title: 'Rekening Bank',
  },
];

export const SETTING_MENU_SIDEBAR_ITEMS: ItemType[] = [
  {
    key: 'setting',
    icon: <ProfileIcon />,
    label: 'Pengaturan Akun',
    children: [
      {
        key: 'profile',
        label: <Link to="/setting/profile">Profil</Link>,
      },
      {
        key: 'change-password',
        label: <Link to="/setting/change-password">Ubah Kata Sandi</Link>,
      },
      // Disable for now
      // {
      //   key: 'delete-account',
      //   label: <Link to="/setting/delete-account">Hapus Akun</Link>,
      // },
    ],
  },
  {
    key: 'credit',
    label: <Link to="setting/credit">Kredit</Link>,
    icon: <CreditIcon />,
  },
  {
    key: 'package_price',
    label: <Link to="setting/package-price">Paket Saya</Link>,
    icon: <PackageIcon />,
  },
];

export const ADMIN_FEE = 0;

export const ADMIN_FEE_BEFORE_DISCOUNT = 5000;

export const SERVICE_FEE = 6000;

export const METHOD_TYPE = 'bank_transfer';
