export type TabType = 'offer' | 'legal-drafts';

export enum LEGAL_DRAFT_STATUS {
  CREATED = 'created',
  IN_PROGRESS = 'in_progress',
  IN_REVIEW = 'in_review',
  ACCEPTED = 'accepted',
  NEED_REVISE = 'need_revise',
  IN_REVISE = 'in_revise',
  PASSED_DUE_DATE = 'passed_due_date',
  CANCELLED = 'cancelled',
  APPEALED = 'appealed',
  NOT_COMPLETED = 'not_completed',
}

export enum LEGAL_DRAFT_PAYMENT_STATUS {
  NOT_STARTED = 'not_started',
  EXECUTING = 'executing',
  SUCCESS = 'success',
  FAILED = 'failed',
  LATE = 'late',
}
