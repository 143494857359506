import './style.scss';

import { useEffect } from 'react';

import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  ModalProps,
  Rate,
  Typography,
} from 'antd';

import { CreateConsultationFeatureReview } from 'api/generated/models/case';
import { usePostConsultationsIdReview } from 'api/generated/services/case/consultation/consultation';

interface ConsultationRatingModalProps extends ModalProps {
  defaultRatingValue?: number;
  consultationId?: string;
  onSuccessReview: (rating: number) => void;
}

export const ConsultationRatingModal = ({
  defaultRatingValue,
  consultationId,
  onSuccessReview,
  ...props
}: ConsultationRatingModalProps) => {
  const [form] = Form.useForm();
  const {
    isLoading: isLoadingPostConsultationReview,
    mutate: postConsultationReview,
  } = usePostConsultationsIdReview({
    mutation: {
      onSuccess(data, variables) {
        onSuccessReview(variables?.data?.rating);
      },
    },
  });

  const handleSubmitReview = (values: CreateConsultationFeatureReview) => {
    if (!consultationId) return;

    postConsultationReview({
      id: consultationId as string,
      data: values,
    });
  };

  useEffect(() => {
    form.resetFields();
  }, [consultationId, form]);

  return (
    <Modal
      {...props}
      centered
      className="consultation-rating-modal"
      footer={false}
      width={450}>
      <Form
        className="w-full"
        form={form}
        layout="vertical"
        onFinish={handleSubmitReview}>
        <div className="content">
          <Typography.Title
            className="weight-500 text-center hk-color-black-title font-22"
            color="#404041">
            Beri Ulasan untuk Fitur Chat Konsultasi Hukumku
          </Typography.Title>

          <Form.Item initialValue={defaultRatingValue || 0} name="rating">
            <Rate style={{ fontSize: 36 }} />
          </Form.Item>

          <Divider className="hk-margin-sm" />

          <Form.Item
            className="w-full"
            label={
              <p>
                Tulis ulasan disini{' '}
                <span className="hk-color-heading-2"> (optional)</span>
              </p>
            }
            name="review">
            <Input.TextArea
              placeholder="Tulis detail deskripsi disini..."
              rows={4}
            />
          </Form.Item>
          <Button
            className="w-full hk-margin-top-sm weight-500"
            disabled={isLoadingPostConsultationReview}
            htmlType="submit"
            loading={isLoadingPostConsultationReview}
            type="primary">
            Submit
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
