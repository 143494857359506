import { StatusTagProps } from 'components/status-tag';
import { snakeToTitleCase } from 'utils/format-string';

import { LEGAL_DRAFT_PAYMENT_STATUS, LEGAL_DRAFT_STATUS } from './types';

export const getStatusProperty = (status?: string): StatusTagProps => {
  if (!status) {
    return {
      className: 'hk-tag-color-default',
      text: 'unknown',
    };
  }
  switch (status) {
    case LEGAL_DRAFT_STATUS.CREATED:
      return {
        className: 'hk-tag-color-yellow',
        text: 'Sedang Diulas',
      };

    case LEGAL_DRAFT_STATUS.IN_PROGRESS:
      return {
        className: 'hk-tag-color-yellow',
        text: 'Sedang Diulas',
      };
    case LEGAL_DRAFT_STATUS.IN_REVIEW:
      return {
        className: 'hk-tag-color-yellow',
        text: 'Sedang Diulas',
      };
    case LEGAL_DRAFT_STATUS.ACCEPTED:
      return {
        className: 'hk-tag-color-green',
        text: 'Selesai',
      };
    case LEGAL_DRAFT_STATUS.NEED_REVISE:
      return {
        className: 'hk-tag-color-blue',
        text: 'Butuh Revisi',
      };
    case LEGAL_DRAFT_STATUS.IN_REVISE:
      return {
        className: 'hk-tag-color-yellow',
        text: 'Sedang Direvisi',
      };
    case LEGAL_DRAFT_STATUS.PASSED_DUE_DATE:
      return {
        className: 'hk-tag-color-red',
        text: 'Terlewatkan',
      };
    case LEGAL_DRAFT_STATUS.APPEALED:
      return {
        className: 'hk-tag-color-red',
        text: 'Belum Selesai',
      };
    case LEGAL_DRAFT_STATUS.CANCELLED:
      return {
        className: 'hk-tag-color-red',
        text: 'Cancel',
      };
    case LEGAL_DRAFT_STATUS.NOT_COMPLETED:
      return {
        className: 'hk-tag-color-default',
        text: 'Tidak Selesai',
      };
    case LEGAL_DRAFT_PAYMENT_STATUS.NOT_STARTED:
      return {
        className: 'hk-tag-color-yellow',
        text: 'Pending',
      };

    case LEGAL_DRAFT_PAYMENT_STATUS.EXECUTING:
      return {
        className: 'hk-tag-color-yellow',
        text: 'Pending',
      };
    case LEGAL_DRAFT_PAYMENT_STATUS.SUCCESS:
      return {
        className: 'hk-tag-color-green',
        text: 'Success',
      };
    case LEGAL_DRAFT_PAYMENT_STATUS.FAILED:
      return {
        className: 'hk-tag-color-red',
        text: 'Expired',
      };
    case LEGAL_DRAFT_PAYMENT_STATUS.LATE:
      return {
        className: 'hk-tag-color-red',
        text: 'Expired',
      };

    default:
      return {
        className: 'hk-tag-color-default',
        text: snakeToTitleCase(status),
      };
  }
};
