import { formatDisplayedLatestMessage } from 'utils/format-displayed-latest-message';
import { log } from 'utils/log';

import { ChatMessageContent } from './types/sdk/ChatMessageContent';
import { Contact } from './types/sdk/Contact';
import { Message } from './types/sdk/Message';
import { Tinode } from './types/sdk/Tinode';
import { Topic } from './types/sdk/Topic';
import { TopicMe } from './types/sdk/TopicMe';
import {
  SubscribeMeOptions,
  TinodeAdapter as TinodeAdapterInterface,
} from './types/TinodeAdapter';
import { TinodeAdapterConnectionHandlers } from './types/TinodeAdapterConnectionHandlers';
import { TinodeAdapterConnectOptions } from './types/TinodeAdapterConnectOptions';
import { TinodeAdapterTopicMeHandlers } from './types/TinodeAdapterTopicMeHandlers';
import { initializeTinode } from './utils';

class TinodeAdapter implements TinodeAdapterInterface {
  private static STORAGE_KEY: string = 'tinode';

  private tinode: Tinode;
  private topicMe: TopicMe | null = null;
  private openedTopic: Topic | null = null;

  constructor() {
    this.tinode = initializeTinode();
  }

  private persistData(data: Record<string, any>): void {
    const existingData = localStorage.getItem(TinodeAdapter.STORAGE_KEY);
    const existingDataObject = existingData ? JSON.parse(existingData) : {};
    const newDataObject = {
      ...existingDataObject,
      ...data,
    };
    localStorage.setItem(
      TinodeAdapter.STORAGE_KEY,
      JSON.stringify(newDataObject)
    );
  }

  private getPersistedData(key: string): any | null {
    const existingData = localStorage.getItem(TinodeAdapter.STORAGE_KEY);
    const existingDataObject = existingData ? JSON.parse(existingData) : {};
    return existingDataObject[key] ?? null;
  }

  private async loginToken(): Promise<void> {
    try {
      const authToken = this.getPersistedData('authToken');

      if (authToken && !this.tinode.isAuthenticated()) {
        await this.tinode.loginToken(authToken);
      }
    } catch (error: any) {
      log({ message: '[LOGIN TOKEN ERROR]', error });
      if (error?.code === 401) {
        localStorage.clear();
        window.location.replace('/auth/login');
      }
      if (error?.code === 504) {
        localStorage.clear();
        window.location.replace('/auth/login');
      }
      if (error?.code === 400) {
        localStorage.clear();
        window.location.replace('/auth/login');
      }
    }
  }

  private markOpenedTopicAsRead(): void {
    if (!this.openedTopic) return;

    const topicName = this.openedTopic.name;
    const latestMessage = this.openedTopic.latestMessage();
    if (!latestMessage) return;

    this.tinode.note(topicName, 'read', latestMessage.seq);
  }

  private handleContactUpdate(
    what: string,
    contact: Contact,
    handlers?: TinodeAdapterTopicMeHandlers
  ): void {
    if (['gone', 'on', 'off', 'read', 'unsub'].includes(what)) {
      handlers?.onContactUpdate?.(what, contact);
      return;
    }

    if (what == 'msg' && contact) {
      handlers?.onContactUpdate?.(what, contact);
      return;
    }

    if (what === 'acs') {
      handlers?.onContactPresenceUpdated?.();
      return;
    }

    if (what == 'recv') {
      if (this.openedTopic && this.openedTopic.name === contact.topic) {
        this.markOpenedTopicAsRead();
      } else {
        handlers?.onContactUpdate?.(what, contact);
      }
    }
  }

  getTinode() {
    return this.tinode;
  }

  hasCredentials() {
    const authToken = this.getPersistedData('authToken');
    return Boolean(authToken);
  }

  async connect(options?: TinodeAdapterConnectOptions) {
    this.tinode.onConnect = options?.onConnect ?? this.tinode.onConnect;
    this.tinode.onAutoreconnectIteration = () => {
      this.tinode.reconnect();
    };

    try {
      if (!this.tinode.isConnected()) {
        await this.tinode.connect();
      } else {
        options?.onConnect?.();
      }

      await this.loginToken();

      return this;
    } catch (error) {
      log({ message: '[CONNECT ERROR]', error });
      return this;
    }
  }

  attachConnectionHandlers(handlers: TinodeAdapterConnectionHandlers) {
    this.tinode.onDataMessage =
      handlers?.onDataMessage ?? this.tinode.onDataMessage;
    this.tinode.onPresMessage =
      handlers?.onPresMessage ?? this.tinode.onPresMessage;
    return this;
  }

  disconnect() {
    if (this.tinode.isConnected()) {
      this.tinode.disconnect();
    }
    return this;
  }

  async loginBasic(username: string, password: string) {
    try {
      await this.tinode.loginBasic(username, password);
      const authTokenObject = this.tinode.getAuthToken();
      this.persistData({
        authToken: authTokenObject?.token,
      });

      return this;
    } catch (error: any) {
      log({ message: '[loginBasic error]', error });
      return this;
    }
  }

  async logout() {
    try {
      await this.leaveMe();
      this.tinode.disconnect();
      localStorage.setItem(TinodeAdapter.STORAGE_KEY, '');
      return this;
    } catch (error) {
      log({ message: '[logout error]', error });
      return this;
    }
  }

  attachMeHandlers(handlers: TinodeAdapterTopicMeHandlers) {
    if (this.topicMe) {
      this.topicMe.onContactUpdate = (what: string, contact: Contact) =>
        this.handleContactUpdate(what, contact, handlers);
      this.topicMe.onSubsUpdated =
        handlers?.onSubsUpdated ?? this.topicMe.onSubsUpdated;
    }

    return this;
  }

  async subscribeMe(options?: SubscribeMeOptions) {
    this.topicMe = this.tinode.getMeTopic();

    if (options?.handlers) {
      this.attachMeHandlers(options?.handlers);
    } else {
      this.topicMe.onContactUpdate = this.handleContactUpdate;
    }

    try {
      await this.loginToken();
      await this.topicMe.subscribe(
        this.topicMe
          .startMetaQuery()
          .withData()
          .withSub()
          .withDesc()
          .withCred()
          .build()
      );
      return this;
    } catch (error: any) {
      log({ message: '[subscribe me error]', error });
      return this;
    }
  }

  async leaveMe() {
    try {
      await this.loginToken();
      await this.topicMe?.leave(false);
      this.topicMe = null;
    } catch (error: any) {
      log({ message: '[leave me error]', error });
    }
    return this;
  }

  async subscribeTopic(topicName: string) {
    const topicDetail = this.tinode.getTopic(topicName);
    try {
      await topicDetail.subscribe(
        topicDetail
          .startMetaQuery()
          .withSub()
          .withDesc()
          .withLaterData(100)
          .build(),
        {
          sub: {
            mode: 'JRWP',
          },
        }
      );
      return this;
    } catch (error) {
      log({ message: '[subscribe topic error]', error });
      return this;
    }
  }

  getTopicDetails(topicName: string) {
    const topicDetail = this.tinode.getTopic(topicName);
    const latestMessage = topicDetail.latestMessage();
    const formattedLatestMessage = formatDisplayedLatestMessage(latestMessage);

    return {
      formattedLatestMessage,
      isOnline: topicDetail.online,
      latestMessageSenderTopicName: latestMessage?.from,
      unreadCount: topicDetail.unread,
    };
  }

  getTopicAccessMode(topicName: string) {
    return this.tinode.getTopicAccessMode(topicName);
  }

  async setOpenedTopic(topicName: string | null) {
    if (!topicName) {
      this.openedTopic = null;
      return this;
    }

    this.openedTopic = this.tinode.getTopic(topicName);

    try {
      if (!this.openedTopic.isSubscribed()) {
        await this.subscribeTopic(topicName);
      }
      this.markOpenedTopicAsRead();

      return this;
    } catch (error) {
      log({ message: '[setOpenedTopic]', error });
      return this;
    }
  }

  getOpenedTopic() {
    return this.openedTopic;
  }

  getOpenedTopicMessages() {
    if (!this.openedTopic) return [];

    const messages: Message[] = [];
    this.openedTopic.messages((message: Message) => {
      messages.push({
        content: message.content,
        from: message.from,
        seq: message.seq,
        ts: message.ts,
      });
    });
    this.markOpenedTopicAsRead();

    return messages;
  }

  async sendMessageToOpenedTopic(message: ChatMessageContent) {
    if (!this.openedTopic) return this;

    try {
      await this.tinode.publish(this.openedTopic.name, message, false);
      return this;
    } catch (error) {
      log({ message: '[sendMessageToOpenedTopic]', error });
      return this;
    }
  }
}

export const tinodeAdapter = new TinodeAdapter();
