import { Tag, TagProps } from 'antd';

export interface StatusTagProps extends Partial<TagProps> {
  text: string;
  className: string;
}

export const StatusTag = ({ text, className, ...props }: StatusTagProps) => {
  return (
    <Tag
      className={`hk-padding-x-sm hk-padding-y-xs font-14 ${className}`}
      {...props}>
      {text}
    </Tag>
  );
};
