import './style.scss';

import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormInstance,
  Grid,
  InputNumber,
  Modal,
  ModalProps,
  Row,
  Space,
  Typography,
} from 'antd';
import { useCurrentUser } from 'hooks/useCurrentUser';

import { FormError } from 'components/form-error';
import {
  ADMIN_FEE,
  ADMIN_FEE_BEFORE_DISCOUNT,
  SERVICE_FEE,
} from 'components/page-sidebar/constants';
import { currencyFormatter, priceFormatter } from 'utils';

interface WithdrawModalProps extends ModalProps {
  totalWalletAmount: number;
  onSubmit: any;
  form: FormInstance;
  isLoadingSubmit: boolean;
  isConfirmationModalOpen: boolean;
  onOpenConfirmationModal: () => void;
  onCloseConfirmationModal: () => void;
}

export function WithdrawModal(props: WithdrawModalProps) {
  const screens = Grid.useBreakpoint();
  const { currentLawyerBankAccount } = useCurrentUser();
  const totalWalletAmount = props.totalWalletAmount ?? 0;

  const amount = Form.useWatch('amount', props.form);
  const bankAccountName = currentLawyerBankAccount?.account_name;
  const bankAccountNumber = currentLawyerBankAccount?.account_number;
  const bankName = currentLawyerBankAccount?.bank_name;
  const total = amount - ADMIN_FEE - SERVICE_FEE;

  const confirmationList = [
    { label: 'Jumlah Penarikan', value: currencyFormatter(amount ?? 0) },
    { label: 'Rekening Tujuan', value: bankAccountNumber },
    { label: 'Nama Akun', value: bankAccountName },
    {
      label: 'Biaya Admin',
      value: currencyFormatter(ADMIN_FEE),
      valueBeforeDiscount: currencyFormatter(ADMIN_FEE_BEFORE_DISCOUNT),
    },
    { label: 'Biaya Layanan', value: currencyFormatter(SERVICE_FEE) },
  ];

  return (
    <>
      <Modal
        {...props}
        centered
        className="faq-modal"
        footer={null}
        title={
          <Typography.Title
            className="hk-color-black-title text-title weight-500"
            color="#404041">
            Tarik Dana
          </Typography.Title>
        }
        width={477}>
        <Typography.Paragraph className="font-16 weight-500 hk-color-black-title hk-margin-bottom-none">
          Jumlah Yang Tersedia
        </Typography.Paragraph>
        <Typography.Paragraph className="font-24 weight-500 hk-color-dark-green">
          {currencyFormatter(totalWalletAmount ?? 0)}
        </Typography.Paragraph>

        <Form
          form={props.form}
          id="withdraw-form"
          layout="vertical"
          onFinish={props.onSubmit}
          requiredMark={false}>
          <Form.Item
            label="Jumlah Penarikan"
            name="amount"
            rules={[
              () => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.reject(
                      <FormError message="Masukkan jumlah saldo yang akan ditarik" />
                    );
                  }
                  if (value > totalWalletAmount) {
                    return Promise.reject(
                      <FormError message="Tidak bisa melebihi saldo" />
                    );
                  }
                  if (value < 40000) {
                    return Promise.reject(
                      <FormError message="Minimum penarikan Rp 40.000" />
                    );
                  }

                  return Promise.resolve();
                },
              }),
            ]}>
            <InputNumber
              className="w-full"
              formatter={(value) => {
                if (!value) return '';
                return priceFormatter(value);
              }}
              prefix={
                <Typography.Paragraph className="font-14 weight-400 hk-color-heading-2 hk-margin-bottom-none">
                  Rp
                </Typography.Paragraph>
              }
              size="large"
            />
          </Form.Item>

          <Form.Item
            label={
              <Space direction={screens.md ? 'horizontal' : 'horizontal'}>
                <Typography.Text className="font-14 weight-500 hk-color-black-title">
                  Bank
                </Typography.Text>

                <Typography.Text className="bank-label-info weight-400 hk-color-black-title">
                  <InfoCircleOutlined /> Dana akan ditransfer ke nomor rekening
                  ini
                </Typography.Text>
              </Space>
            }
            name="bank">
            <Card size="small">
              <Space className="hk-padding-none" direction="vertical" size={0}>
                <Space
                  align="center"
                  className="hk-padding-none"
                  direction="horizontal"
                  size={0}>
                  <Typography.Paragraph className="hk-margin-bottom-none font-14 weight-400 hk-color-black-title">
                    {bankAccountName}
                  </Typography.Paragraph>
                </Space>
                <Space>
                  <Typography.Paragraph className="hk-margin-bottom-none font-14 weight-400 hk-color-black-title">
                    {`${bankName} - ${bankAccountNumber}`}
                  </Typography.Paragraph>
                </Space>
              </Space>
            </Card>
          </Form.Item>
        </Form>

        <Alert
          className="faq-alert hk-margin-top-sm hk-margin-bottom-md"
          message={
            <>
              <Typography.Paragraph className="hk-color-black-title font-11 weight-400 hk-margin-bottom-none">
                Minimum penarikan adalah Rp40.000
              </Typography.Paragraph>
              <Typography.Paragraph className="hk-color-black-title font-11 weight-400 hk-margin-bottom-none">
                Penarikan dana akan diproses dalam 3-5 hari kerja oleh tim
                Hukumku
              </Typography.Paragraph>
            </>
          }
          showIcon
          type="info"
        />
        <Button
          className="w-full"
          onClick={async () => {
            await props.form.validateFields();
            props.onOpenConfirmationModal();
          }}
          type="primary">
          Ajukan Penarikan
        </Button>
      </Modal>

      <Modal
        centered
        className="faq-modal"
        footer={null}
        onCancel={props.onCloseConfirmationModal}
        open={props.isConfirmationModalOpen}
        title={
          <Typography.Title
            className="hk-color-black-title text-title weight-500"
            color="#404041">
            Ringkasan Penarikan
          </Typography.Title>
        }
        width={477}>
        {confirmationList.map(
          ({ label, value, valueBeforeDiscount }, index) => {
            return (
              <Row
                align="middle"
                className="confirmation-list"
                justify="space-between"
                key={index}>
                <Col span={12}>
                  <Typography.Paragraph className="hk-margin-bottom-none font-14 weight-400">
                    {label}
                  </Typography.Paragraph>
                </Col>
                <Col span={12}>
                  {valueBeforeDiscount ? (
                    <Typography.Paragraph
                      className="hk-margin-bottom-none hk-align-right font-14 weight-400"
                      delete>
                      {valueBeforeDiscount}
                    </Typography.Paragraph>
                  ) : (
                    <Typography.Paragraph className="hk-margin-bottom-none hk-align-right font-14 weight-400">
                      {value}
                    </Typography.Paragraph>
                  )}
                </Col>
              </Row>
            );
          }
        )}

        <Alert
          className="total-alert"
          message={
            <Row align="middle" justify="space-between">
              <Col span={12}>
                <Typography.Paragraph className="hk-margin-bottom-none font-14 weight-400">
                  Total Pendapatan
                </Typography.Paragraph>
              </Col>
              <Col span={12}>
                <Typography.Paragraph className="hk-margin-bottom-none hk-align-right font-14 weight-500">
                  {currencyFormatter(total ?? 0)}
                </Typography.Paragraph>
              </Col>
            </Row>
          }
          type="info"
        />

        <Row className="hk-margin-top-md" gutter={[24, 0]}>
          <Col span={12}>
            <Button className="w-full" onClick={props.onCloseConfirmationModal}>
              Kembali
            </Button>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Button
                className="w-full"
                disabled={props.isLoadingSubmit}
                form="withdraw-form"
                htmlType="submit"
                key="submit"
                loading={props.isLoadingSubmit}
                type="primary">
                Konfirmasi
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
