import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ConfigProvider, message } from 'antd';

import { Error } from 'api/generated/models/auth';
import { theme } from 'config/antd-theme';
import { useAuthStore } from 'store/auth';
import { useChatStore } from 'store/chat';
import { useCurrentUserStore } from 'store/current-user';

export interface AppProvidersProps {
  children: ReactNode;
}

export function AppProviders({ children }: AppProvidersProps) {
  const { t } = useTranslation(['error-message']);
  const { reset: resetAuth } = useAuthStore((state) => state.actions);
  const resetCurrentUser = useCurrentUserStore((state) => state.actions.reset);
  const resetChat = useChatStore((state) => state.actions.reset);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnWindowFocus: false,
      },
    },
    queryCache: new QueryCache({
      // TODO: Error type
      onError: (error: any, query: any) => {
        if (error?.response?.status === 401) {
          resetAuth();
          window.location.replace('/auth/login');
          return;
        }
        if (error?.response?.status === 403) {
          resetAuth();
          window.location.replace('/auth/login');
          return;
        }
        if (error?.response?.status === 423) {
          resetAuth();
          resetCurrentUser();
          resetChat();
          // This clear is experimental, we need to check if it's necessary
          localStorage.clear();
          window.location.replace('/verification/banned');
          return;
        }

        // disable default error message in case customized error handling needed
        if (query.options?.meta?.disableDefaultErrorHandler) return;

        // 🎉 only show error toasts if we already have data in the cache
        // which indicates a failed background update
        if (query.state.status === 'error') {
          const errorMessage =
            error?.response?.data?.message ??
            `Something went wrong: ${error.message}`;
          return message.error(errorMessage);
        }
      },
    }),
    mutationCache: new MutationCache({
      onError: (error: any) => {
        if (
          typeof error.response.data === 'object' &&
          !error.response.data.detail
        ) {
          return message.error(error.response.data.message);
        }

        const errorResponse = error.response.data as Error;
        if (errorResponse?.detail?.length) {
          errorResponse.detail.forEach((eachError) => {
            const errorMessage = t(eachError.code);
            return message.error(errorMessage);
          });
        }
      },
    }),
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider theme={theme}>{children}</ConfigProvider>
    </QueryClientProvider>
  );
}
